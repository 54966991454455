import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { TransitionState } from 'gatsby-plugin-transition-link'

import Layout from '@components/Layout'
import HeroContainer from './HeroContainer'
import JoinUsContainer from './JoinUsContainer'
import CompanyHighlightsContainer from './CompanyHighlightsContainer'

import { parseImage, parseDescription, parseAnchor } from '@utils/contentful'
import AnnouncementContainer from './AnnouncementContainer'
import PartnersAndTeamContainer from './PartnersAndTeamContainer'

const elements = {
  ContentfulHomeHero: HeroContainer,
  ContentfulHomeAnnouncement: AnnouncementContainer,
  // ContentfulHomeGivingBack: GivingBackContainer,
  ContentfulHomeJoinUs: JoinUsContainer,
  // ContentfulHomePress: PressContainer,
  ContentfulHomeCompanyHighlights: CompanyHighlightsContainer,
  ContentfulHomePartnersAndTeam: PartnersAndTeamContainer,
}

const IndexPage = ({ data }) => {
  const [opacityStyle, setOpacityStyle] = useState()
  const handleOpacityChange = style => {
    setOpacityStyle(style)
  }

  const {
    allContentfulHomePage: { edges },
  } = data
  const hideAnnouncement = data.allContentfulHomePage.edges[0].node.hideAnnouncement
  const { title = '', modules = [] } = edges && edges.length > 0 ? edges[0].node : {}
  let content = modules.map(({ __typename, ...data }) => {
    const props = {}

    switch (__typename) {
      case 'ContentfulHomeHero':
        props.opacityStyle = opacityStyle
        props.heading = data.header && data.header.header
        props.body = data.header && data.header.description && parseDescription(data.header.description)
        break
      case 'ContentfulHomeAnnouncement':
        props.opacityStyle = opacityStyle
        props.heading = data.title
        props.body = data.description && parseDescription(data.description)
        props.media = data.imagevideo && parseImage(data.imagevideo)
        props.backgroundColor = data.backgroundColor
        props.textColor = data.textColor
        props.useOrangeGradientHeader = data.useOrangeGradientHeader
        break
      case 'ContentfulHomePartnersAndTeam':
        props.data = data
        props.opacityStyle = opacityStyle
        break
      case 'ContentfulHomeJoinUs':
        props.link = data.link
        props.header = data.header && data.header.header
        props.description = data.header && data.header.description && parseDescription(data.header.description)
        break
      case 'ContentfulHomeCompanyHighlights':
        props.anchor = data.anchor && parseAnchor(data.anchor.url)
        props.highlights = data.highlights.map((item, index) => {
          return {
            key: index,
            cta: item.cta,
            name: item.name,
            header: item.header,
            description: item.description && item.description.internal.content,
            url: item.url,
            offeringTitle: item.productOfferingsTitle,
            offering: item.productOfferings ? item.productOfferings : [],
            primaryColor: item.primaryColor,
            secondaryColor: item.secondaryColor,
            heroLogo: {
              title: item.heroLogo && item.heroLogo.title,
              file: {
                url: item.heroLogo && item.heroLogo.file.url,
              },
            },
            menuLogo: {
              title: item.menuLogo && item.menuLogo.title,
              file: {
                url: item.menuLogo && item.menuLogo.file.url,
              },
            },
          }
        })
        break
      default:
        break
    }

    return {
      Element: elements[__typename] || 'div',
      props,
      type: __typename,
    }
  })

  content = content.filter(i => {
    if (hideAnnouncement) {
      return i.type != 'ContentfulHomeAnnouncement'
    } else {
      return i
    }
  })

  return (
    <TransitionState>
      {({ transitionStatus } = {}) => {
        return (
          <Layout
            className={transitionStatus}
            onOpacityChange={handleOpacityChange}
            seo={{ title }}
            smoothScroll={true}
          >
            {content.map(({ Element, props, type }) => (
              <Element {...props} key={type} />
            ))}
          </Layout>
        )
      }}
    </TransitionState>
  )
}

IndexPage.propTypes = {
  data: PropTypes.object,
}

export default IndexPage
