import React from 'react'
import BaseSvg from '@components/BaseSvg'

const Right = props => (
  <BaseSvg width="100%" viewBox="0 0 17 32">
    <path
      d="M1 1.858L15.142 16 1 30.142"
      stroke="#FFF"
      strokeWidth="2"
      fill="none"
      fillRule="evenodd"
      strokeLinecap="round"
    />
  </BaseSvg>
)

export default Right
