import React, { useState, useEffect } from 'react'
import classnames from 'classnames/bind'
import { useSwipeable } from 'react-swipeable'
import PropTypes from 'prop-types'

import ReactMarkdown from 'react-markdown'
import Text from '@components/Text'
import CTA from '@components/CTA'
import Menu from '@components/Carousel/Menu'
import Arrow from '@components/Carousel/Arrow'

import { TIMING } from './constants'

import style from './CompanyHighlight.module.scss'

const cx = classnames.bind(style)

const CompanyHighlight = ({ autoSlideEnabled, time, inView, data, handleColorChange }) => {
  const [isTouch, changeIsTouch] = useState(false)
  useEffect(() => {
    window.addEventListener('touchstart', () => !isTouch && changeIsTouch(true))
    window.addEventListener('mousemove', () => isTouch && changeIsTouch(true))

    return () => {
      window.removeEventListener('touchstart', () => !isTouch && changeIsTouch(true))
      window.removeEventListener('mousemove', () => isTouch && changeIsTouch(true))
    }
  }, [isTouch])

  const currentSlideSetter = key => {
    const newSlide = data.filter(item => {
      return item.key === key
    })

    if (newSlide.length) {
      handleColorChange(newSlide[0].primaryColor)
      return newSlide[0]
    } else {
      handleColorChange(currentSlide.primaryColor)
      return currentSlide
    }
  }

  const [currentSlideIndex, setCurrentSlideIndex] = useState(0)
  const [currentSlide, setCurrentSlide] = useState(currentSlideSetter(currentSlideIndex))

  const swipeConfig = {
    delta: 10,
    preventDefaultTouchmoveEvent: false,
    trackTouch: true,
    trackMouse: true,
    rotationAngle: 0,
  }
  const handlers = useSwipeable({ onSwiped: e => handleSwipe(e), ...swipeConfig })

  const handleSwipe = e => {
    if (e.dir) {
      const result = handleDirection(e.dir.toLowerCase())
      result()
    }
  }

  const updateCurrent = key => {
    return () => {
      setAutoSlide(key)
      setCurrentSlideIndex(key)
      setCurrentSlide(currentSlideSetter(key))
    }
  }

  const checkMinMax = value => {
    const max = data.length - 1
    const min = 0

    if (value < min) {
      value = max
    }

    if (value > max) {
      value = min
    }

    return value
  }

  const handleDirection = dir => {
    return () => {
      let next = currentSlide.key

      if (dir === 'right') {
        next--
      }
      if (dir === 'left') {
        next++
      }

      next = checkMinMax(next)

      if (next !== currentSlide.key) {
        const result = updateCurrent(next)
        result()
      }
    }
  }

  // eslint-disable-next-line
  const [autoSlide, setAutoSlide] = useState(0)
  useEffect(() => {
    const interval = setInterval(() => {
      setAutoSlide(autoSlide => {
        const now = Date.now()
        const delta = now - time

        if (!autoSlideEnabled && delta < TIMING.timeout && !isTouch) {
          return autoSlide
        }

        if (isTouch && inView) {
          return autoSlide
        }

        autoSlide++
        autoSlide = checkMinMax(autoSlide)

        setCurrentSlideIndex(autoSlide)
        setCurrentSlide(currentSlideSetter(autoSlide))
        return autoSlide
      })
    }, TIMING.interval)
    return () => clearInterval(interval)

    // eslint-disable-next-line
  }, [autoSlideEnabled, time, isTouch, inView])

  const offerings = currentSlide.offering.map((_, index) => (
    <Text tag="p" type="list" className={cx('offerings')} key={index}>
      {_}
    </Text>
  ))

  const heroLogos = data.map((_, index) => (
    <img
      key={index}
      className={cx('icon', { selected: _.key === currentSlideIndex })}
      src={_.heroLogo.file.url}
      alt={_.heroLogo.title}
    />
  ))

  const menuLogos = data.map(_ => {
    return {
      name: _.name,
      url: _.menuLogo.file.url,
      url_small: _.heroLogo.file.url,
      alt: _.menuLogo.title,
      secondaryColor: _.secondaryColor,
    }
  })

  const largeCols = data.length === 5 ? 7 : 8

  return (
    <div style={{ backgroundColor: currentSlide.primaryColor }} className={cx('billboard')}>
      <div className={cx('grid', { 'tablet-grid': data.length < 7 })}>
        <div
          className={cx('col-l', 'col-l-o-2', `col-l-${largeCols}`, 'col-m', `col-m-7`, 'menuContainer', {
            fullMedium: data.length > 5,
          })}
        >
          <Menu
            onClick={updateCurrent}
            currentSlideIndex={currentSlideIndex}
            currentSlide={currentSlide}
            menuLogos={menuLogos}
          />
        </div>
      </div>

      <div {...handlers}>
        <div className={cx('iconHolder')}>{heroLogos}</div>

        <div
          style={{ color: currentSlide.secondaryColor }}
          className={cx('grid', 'tablet-grid', 'mobile-grid', 'content', 'headerHolder')}
        >
          <div
            className={cx(
              'col-l',
              'col-l-o-2',
              'col-l-4',
              'col-m',
              'col-m-o-1',
              'col-m-5',
              'col-s',
              'col-s-6',
              'headerCols'
            )}
          >
            <Text tag="h2" type="h1" className={cx('header')}>
              {currentSlide.header}
            </Text>
          </div>
        </div>

        <div
          style={{ color: currentSlide.secondaryColor }}
          className={cx('grid', 'tablet-grid', 'mobile-grid', 'content', 'aside')}
        >
          <div
            className={cx('col-l', 'col-l-o-6', 'col-l-3', 'col-m-o-4', 'col-m-3', 'col-s', 'col-s-10', 'asideContent')}
          >
            <Text tag="div" type="b1" className={cx('description')}>
              <ReactMarkdown>{currentSlide.description}</ReactMarkdown>
            </Text>

            {offerings.length > 0 && (
              <>
                <div className={cx('divider')} />
                <Text tag="p" type="eyebrow" className={cx('offerings', 'offeringsTitle')}>
                  {currentSlide.offeringTitle}
                </Text>
                {offerings}
              </>
            )}
          </div>
        </div>

        <Arrow className={cx('arrow', 'left')} onClick={handleDirection('right')} type="left" />
        <Arrow className={cx('arrow', 'right')} onClick={handleDirection('left')} type="right" />

        <div className={cx('ctaHolder')}>
          <div className={cx('grid', 'tablet-grid')}>
            <div className={cx('col-m', 'col-m-3', 'col-l', 'col-l-o-2')}>
              <div className={cx('ctaButtonHolder')}>
                <CTA type={'secondary'} className={cx('cta')} to={currentSlide.url}>
                  {currentSlide.cta}
                </CTA>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default CompanyHighlight

CompanyHighlight.propTypes = {
  autoSlideEnabled: PropTypes.bool,
  inView: PropTypes.bool,
  time: PropTypes.number,
  handleColorChange: PropTypes.func,
  data: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.number,
      cta: PropTypes.string,
      name: PropTypes.string,
      description: PropTypes.string,
      url: PropTypes.string,
      header: PropTypes.string,
      offeringTitle: PropTypes.string,
      offering: PropTypes.array,
      primaryColor: PropTypes.string,
      secondaryColor: PropTypes.string,
      heroLogo: PropTypes.shape({
        title: PropTypes.string.isRequired,
        file: PropTypes.shape({
          url: PropTypes.string,
        }),
      }),
      menuLogo: PropTypes.shape({
        title: PropTypes.string.isRequired,
        file: PropTypes.shape({
          url: PropTypes.string,
        }),
      }),
    })
  ),
}
