import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames/bind'

import GradientText from '@components/GradientText'
import Text from '@components/Text'
import { InlineMarkdown } from '@components/Markdown'

import styles from './Hero.module.scss'
import {
  gradientColor1,
  gradientColor2,
  gradientColor3,
  gradientStop1,
  gradientStop2,
  gradientStop3,
} from '@styles/variables.module.scss'

const cx = classnames.bind(styles)

const Hero = ({
  title,
  subtitle,
  textColor = '#434449',
  useOrangeGradientHeader = true,
  titleColClassNames,
  subtitleColClassNames,
  className,
  heroTextClassNames,
}) => {
  return (
    <div className={cx('grid', 'mobile-grid', 'tablet-grid', 'content', className)} style={{ color: textColor }}>
      <div className={cx(titleColClassNames, 'title')}>
        {useOrangeGradientHeader && (
          <GradientText
            tag="h1"
            className={cx('hero', heroTextClassNames)}
            type="h1"
            colors={[gradientColor1, gradientColor2, gradientColor3]}
            colorStops={[gradientStop1, gradientStop2, gradientStop3]}
          >
            {title}
          </GradientText>
        )}
        {!useOrangeGradientHeader && (
          <Text tag="h1" type="h1" color={textColor}>
            {title}
          </Text>
        )}
      </div>
      <div className={cx('subtitle')}>
        {subtitle && (
          <InlineMarkdown className={cx('subhead', subtitleColClassNames)} color={textColor}>
            {subtitle}
          </InlineMarkdown>
        )}
      </div>
    </div>
  )
}

Hero.defaultProps = {
  titleColClassNames: ['col-l', 'col-l-o-2', 'col-l-6', 'col-m', 'col-m-7', 'col-s', 'col-s-10'],
  subtitleColClassNames: ['col-l', 'col-l-o-2', 'col-l-5', 'col-m', 'col-m-6', 'col-s', 'col-s-9'],
}

Hero.propTypes = {
  className: PropTypes.string,
  heroTextClassNames: PropTypes.string,
  titleColClassNames: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.string)]),
  subtitleColClassNames: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.string)]),
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string.isRequired,
}

export default Hero
