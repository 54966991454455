import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames/bind'

import { TYPES } from './constants'
import Right from './svg/Right'
import Left from './svg/Left'

import styles from './Arrow.module.scss'
const cx = classnames.bind(styles)

const CarouselArrow = ({ className, type, onClick, ...props }) => {
  return (
    <button className={cx('arrow-holder', `${className}`)} onClick={onClick}>
      {type === 'right' && <Right />}
      {type === 'left' && <Left />}
    </button>
  )
}

CarouselArrow.propTypes = {
  type: PropTypes.oneOf(TYPES).isRequired,
  onClick: PropTypes.func.isRequired,
}

export default CarouselArrow
