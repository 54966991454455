import React from 'react'
import PropTypes from 'prop-types'

import Hero from '@components/Hero'

import styles from './Announcement.module.scss'
import classnames from 'classnames/bind'

const cx = classnames.bind(styles)

const Announcement = ({
  body,
  heading,
  media,
  className,
  backgroundColor,
  textColor,
  useOrangeGradientHeader,
  heroTextClassNames,
  titleColClassNames,
  subtitleColClassNames,
}) => (
  <div
    className={heading != 'NONE' ? cx('announcement-container', className) : cx('hide-announcement')}
    style={{ background: backgroundColor, color: textColor }}
  >
    <div style={{ flex: 1 }} className={cx('hero')}>
      {heading != 'NONE' ? (
        <Hero
          title={heading}
          subtitle={body}
          textColor={textColor}
          useOrangeGradientHeader={useOrangeGradientHeader}
          heroTextClassNames={heroTextClassNames}
          subtitleColClassNames={subtitleColClassNames}
          titleColClassNames={titleColClassNames}
        />
      ) : (
        ''
      )}
    </div>
    {media && (
      <div className={cx('actual-image-container')}>
        <div className={cx('announcement-image')}>
          {media.url.includes('.mp4') ? (
            <video className={cx('media')} controls>
              Your browser does not support the &lt;video&gt; tag.
              <source src={media.url} />
            </video>
          ) : (
            <img className={cx('media')} src={media.url} alt={media.description} />
          )}
        </div>
      </div>
    )}
  </div>
)

Announcement.propTypes = {
  opacityStyle: PropTypes.object,
  body: PropTypes.string.isRequired,
  heading: PropTypes.string.isRequired,
  className: PropTypes.string,
  media: PropTypes.shape({
    url: PropTypes.string,
    description: PropTypes.string,
  }),
  backgroundColor: PropTypes.string.isRequired,
  textColor: PropTypes.string.isRequired,
  useOrangeGradientHeader: PropTypes.bool.isRequired,
  heroTextClassNames: PropTypes.string,
  circleLogoClassName: PropTypes.string,
  titleColClassNames: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.string)]),
  subtitleColClassNames: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.string)]),
}

export default Announcement
