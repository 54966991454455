import React from 'react'
import BaseSvg from '@components/BaseSvg'

const Left = props => (
  <BaseSvg width="100%" viewBox="0 0 17 32">
    <path
      d="M16 30.142L1.858 16 16 1.858"
      stroke="#FFF"
      strokeWidth="2"
      fill="none"
      fillRule="evenodd"
      strokeLinecap="round"
    />
  </BaseSvg>
)

export default Left
