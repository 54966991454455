import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import smoothscroll from 'smoothscroll-polyfill'
import 'intersection-observer'
import { useInView } from 'react-intersection-observer'
import classnames from 'classnames/bind'
import { graphql } from 'gatsby'

import CompanyHighlight from '@components/Carousel/CompanyHighlight'

import styles from './CompanyHighlightsContainer.module.scss'
const cx = classnames.bind(styles)

const CompanyHighlightsContainer = ({ highlights, anchor, className }) => {
  const firstHighlight = highlights && highlights.find(item => item.key === 0)
  const initialBg = firstHighlight ? firstHighlight.primaryColor : '#ffffff'
  const [backgroundColor, setBackgroundColor] = useState(initialBg)
  const [autoSlideEnabled, setAutoSlideEnabled] = useState(true)
  const [time, setTime] = useState(Date.now())
  const [ref, inView] = useInView({
    threshold: 0.7,
  })

  const handleMouseEnter = () => {
    setAutoSlideEnabled(false)
    setTime(Date.now())
  }
  const handleMouseLeave = () => {
    setAutoSlideEnabled(true)
    setTime(Date.now())
  }
  const handleClick = () => {
    setTime(Date.now())
  }
  const handleColorChange = color => {
    setBackgroundColor(color)
  }

  useEffect(() => {
    smoothscroll.polyfill()
  }, [])

  return (
    <div
      style={{ backgroundColor }}
      className={cx('container', className)}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      onClick={handleClick}
      ref={ref}
      id={anchor}
    >
      <div style={{ backgroundColor }} className={cx('overflow', 'overflow-left')} />
      <div style={{ backgroundColor }} className={cx('overflow', 'overflow-right')} />
      <CompanyHighlight
        data={highlights}
        autoSlideEnabled={autoSlideEnabled}
        time={time}
        inView={inView}
        handleColorChange={handleColorChange}
      />
    </div>
  )
}

CompanyHighlightsContainer.propTypes = {
  highlights: PropTypes.arrayOf(PropTypes.object),
  anchor: PropTypes.string,
  className: PropTypes.string,
}

export default CompanyHighlightsContainer

export const query = graphql`
  fragment HighlightsFragment on ContentfulHomeCompanyHighlights {
    highlights {
      id
      cta
      name
      description {
        internal {
          content
        }
      }
      url
      header
      productOfferingsTitle
      productOfferings
      primaryColor
      secondaryColor
      heroLogo {
        title
        file {
          url
        }
      }
      menuLogo {
        title
        file {
          url
        }
      }
    }
    anchor {
      url
    }
  }
`
