import IndexPage from '@components/IndexPage'
import { graphql } from 'gatsby'

export const query = graphql`
  query {
    allContentfulHomePage {
      edges {
        node {
          title
          hideAnnouncement
          modules {
            __typename
            ...HeroDataFragment
            ...AnnouncementDataFragment
            ...JoinUsDataFragment
            ...HighlightsFragment
            ...PartnersAndTeamDataFragment
          }
        }
      }
    }
  }

  fragment TeamDataFragment on ContentfulHomeTeam {
    anchor {
      url
    }
    title: header
    subHeader {
      subHeader
    }
    members {
      image {
        description
        file {
          url
        }
      }
      name
      title
      company
      education {
        education
      }
      fullTitle
      experience {
        experience
      }
      displaySection
    }
  }
`

export default IndexPage
