import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames/bind'

import Text from '@components/Text'
import GradientText from '@components/GradientText'
import CTA from '@components/CTA'
import { Markdown } from '@components/Markdown'

import styles from './SectionHeader.module.scss'
import {
  gradientColor1,
  gradientColor2,
  gradientColor3,
  gradientStop1,
  gradientStop2,
  gradientStop3,
} from '@styles/variables.module.scss'

const cx = classnames.bind(styles)

const SECTION_HEADER_THEME = {
  centered: 'centered',
  largeGradientTitle: 'largeGradientTitle',
}

const SectionHeader = ({ className, theme, title, text, cta }) => {
  const isLargeGradientTitle = theme === SECTION_HEADER_THEME['largeGradientTitle']
  return (
    <div className={cx('container', `container-${theme}`, className)}>
      {isLargeGradientTitle ? (
        <GradientText
          className={cx('main')}
          tag="h1"
          type="h1"
          colors={[gradientColor1, gradientColor2, gradientColor3]}
          colorStops={[gradientStop1, gradientStop2, gradientStop3]}
        >
          {title}
        </GradientText>
      ) : (
        <Text tag="h3" type="h3">
          {title}
        </Text>
      )}
      {text && <Markdown>{text}</Markdown>}
      {cta && (
        <CTA className={cx('cta')} to={cta.url} type="primary">
          {cta.label}
        </CTA>
      )}
    </div>
  )
}

SectionHeader.defaultProps = {
  theme: SECTION_HEADER_THEME['centered'],
}

SectionHeader.propTypes = {
  className: PropTypes.string,
  theme: PropTypes.oneOf(Object.keys(SECTION_HEADER_THEME)),
  title: PropTypes.string.isRequired,
  text: PropTypes.string,
  cta: PropTypes.shape({
    label: PropTypes.string.isRequired,
    url: PropTypes.string.isRequired,
  }),
}

export default SectionHeader
