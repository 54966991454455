import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames/bind'
import { useBreakpoint, useClient } from '@utils/hooks'

import SmallCircle from './SmallCircle'
import MediumCircle from './MediumCircle'
import LargeCircle from './LargeCircle'

import style from './Group1001Circle.module.scss'

const cx = classnames.bind(style)

const Group1001Circle = ({ className, size, ...props }) => {
  const isClient = useClient()
  const breakpoint = useBreakpoint()

  if (!isClient) {
    return null
  }

  const classes = cx('svg', { 'svg-large': size === 'large' }, className)

  if (breakpoint === 'large') {
    return <LargeCircle {...props} className={classes} />
  }

  if (breakpoint === 'medium') {
    return <MediumCircle {...props} className={classes} />
  }

  return <SmallCircle {...props} className={classes} />
}

Group1001Circle.propTypes = {
  style: PropTypes.object,
  className: PropTypes.string,
  size: PropTypes.oneOf(['small', 'large']),
}

export default Group1001Circle
