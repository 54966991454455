import React, { useRef, useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames/bind'

import VisuallyHidden from '@components/VisuallyHidden'

import styles from './Menu.module.scss'
const cx = classnames.bind(styles)

const Menu = ({ onClick, currentSlideIndex, currentSlide, menuLogos }) => {
  const carouselRef = useRef(null)

  const [debounce, setDebounce] = useState(0)

  const [hasTouch, setHasTouch] = useState(false)
  const handleTouch = num => {
    return () => {
      if (!hasTouch) {
        setHasTouch(true)
      }
      setDebounce(Date.now() + num)
    }
  }

  const active = useRef(null)
  const [offset, setOffset] = useState(0)
  useEffect(() => {
    setOffset(parseFloat(active.current.offsetLeft) / 10.0)

    if (Date.now() < debounce) {
      return
    }
    setDebounce(Date.now() + 100)

    const left = active.current.offsetLeft - carouselRef.current.scrollLeft

    const scrollLeft = () => {
      carouselRef.current.scrollTo({
        top: 0,
        left: currentSlideIndex === 0 ? 0 : active.current.offsetLeft,
        behavior: 'smooth',
      })
    }
    if (left > carouselRef.current.offsetWidth) {
      scrollLeft()
    }
    if (left < 0) {
      scrollLeft()
    }
  }, [currentSlideIndex])

  const indicatorStyle = {
    marginLeft: `${offset}rem`,
    backgroundColor: currentSlide.secondaryColor,
  }

  const logos = menuLogos.map((_, index) => {
    return (
      <li key={index} className={cx('slide-holder')}>
        <button
          className={
            index == 5
              ? cx('rvi-logo-holder', 'slide', { active: currentSlideIndex === index, noTouch: !hasTouch })
              : cx('logo-holder', 'slide', { active: currentSlideIndex === index, noTouch: !hasTouch })
          }
          ref={currentSlideIndex === index ? active : null}
          onClick={onClick(index)}
        >
          <VisuallyHidden>{_.name}</VisuallyHidden>
          <span role="presentation" className={cx('fit-content')}>
            <img className={cx('logo')} src={_.url} alt={_.title || ''} />
          </span>
        </button>
      </li>
    )
  })
  return (
    <div
      ref={carouselRef}
      className={cx('carousel', `items-${menuLogos.length}`)}
      onTouchStart={handleTouch(5000)}
      onTouchMove={handleTouch(5000)}
    >
      <div style={indicatorStyle} className={cx('indicator', `indicatorSlide${currentSlide.key}`)} />
      <ul className={cx('slider')}>{logos}</ul>
    </div>
  )
}

Menu.propTypes = {
  onClick: PropTypes.func.isRequired,
  currentSlideIndex: PropTypes.number,
  currentSlide: PropTypes.object,
  menuLogos: PropTypes.array,
}

export default Menu
