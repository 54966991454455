const getPercentage = (currentValue, total) => Math.round((currentValue / total) * 100)

export const supportGradientTextAnimation = () =>
  typeof CSS !== 'undefined' &&
  (CSS.supports('background-clip', 'text') || CSS.supports('-webkit-background-clip', 'text'))

export const changeGradient = ({ percentageX, percentageY, target }, options) => {
  const { colors, stops } = options
  target.style.backgroundImage = `radial-gradient(circle at ${percentageX}% ${percentageY}%, ${colors[0]} ${
    stops[0]
  }, ${colors[1]} ${stops[1]}, ${colors[2]} ${stops[2]})`
}

export const getCoordinatesOnScroll = (event, target) => {
  const { top } = target.getBoundingClientRect()
  const { scrollY } = window
  const verticalDistance = top + target.clientHeight
  if (verticalDistance <= 0) {
    return {}
  }

  const { clientWidth } = target
  const percentage = (getPercentage(scrollY / 2, clientWidth) % 300) - 100
  return {
    x: percentage,
    y: 100 - percentage,
  }
}

export const getCoordinatesOnMouseMove = ({ clientX, clientY }, target) => {
  const { left, top } = target.getBoundingClientRect()
  const { clientWidth, clientHeight } = target
  const mouseX = getPercentage(clientX - left, clientWidth)
  const mouseY = getPercentage(clientY - top, clientHeight)

  return {
    x: mouseX,
    y: mouseY,
  }
}

export const animateTextGradientOnMovement = (event, target, { scroll, ...options }) => {
  if (!supportGradientTextAnimation()) {
    return
  }

  let coordinates

  if (scroll) {
    coordinates = getCoordinatesOnScroll(event, target)
  } else {
    coordinates = getCoordinatesOnMouseMove(event, target)
  }

  if (!coordinates) {
    return
  }

  changeGradient({ percentageX: coordinates.x, percentageY: coordinates.y, target }, options)
}
