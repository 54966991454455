import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames/bind'
import { graphql } from 'gatsby'

import HeroWithCircleLogo from '@components/HeroWithCircleLogo'

import styles from './HeroContainer.module.scss'
const cx = classnames.bind(styles)

const HeroContainer = props => <HeroWithCircleLogo circleLogoClassName={cx('circleLogo')} {...props} />

export const query = graphql`
  fragment HeroDataFragment on ContentfulHomeHero {
    header {
      header
      description {
        internal {
          content
        }
      }
    }
  }
`

HeroContainer.propTypes = {
  opacityStyle: PropTypes.object,
  body: PropTypes.string.isRequired,
  heading: PropTypes.string.isRequired,
}

export default HeroContainer
