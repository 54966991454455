import React, { useEffect, useRef, useState } from 'react'
import PropTypes from 'prop-types'

import Text from '@components/Text'
import classnames from 'classnames'
import styles from './GradientText.module.scss'
import { animateTextGradientOnMovement, supportGradientTextAnimation } from '@utils/gradientAnimation'

const GradientText = ({ children, className, colors, colorStops, style, ...props }) => {
  const textRef = useRef(null)
  const [isTouch, changeIsTouch] = useState(false)
  const background = supportGradientTextAnimation()
    ? `radial-gradient(circle at 50% 50%, ${colors[0]} ${colorStops[0]}, ${colors[1]} ${colorStops[1]}, ${colors[2]} ${
        colorStops[2]
      })`
    : null

  useEffect(() => {
    const element = textRef.current

    window.addEventListener('touchstart', () => !isTouch && changeIsTouch(true))
    window.addEventListener('mousemove', () => isTouch && changeIsTouch(true))

    if (isTouch) {
      window.removeEventListener('mousemove', e =>
        animateTextGradientOnMovement(e, element, { colors, stops: colorStops })
      )
      window.addEventListener('scroll', e =>
        animateTextGradientOnMovement(e, element, { colors, stops: colorStops, scroll: true })
      )
    } else {
      window.removeEventListener('scroll', e =>
        animateTextGradientOnMovement(e, element, { colors, stops: colorStops, scroll: true })
      )
      window.addEventListener('mousemove', e =>
        animateTextGradientOnMovement(e, element, { colors, stops: colorStops })
      )
    }

    return () => {
      window.removeEventListener('touchstart', () => !isTouch && changeIsTouch(true))
      window.removeEventListener('mousemove', () => isTouch && changeIsTouch(true))

      if (isTouch) {
        window.removeEventListener('mousemove', e =>
          animateTextGradientOnMovement(e, element, { colors, stops: colorStops })
        )
      } else {
        window.removeEventListener('scroll', e =>
          animateTextGradientOnMovement(e, element, { colors, stops: colorStops, scroll: true })
        )
      }
    }
  }, [textRef, isTouch, colors, colorStops])

  return (
    <Text
      ref={textRef}
      className={classnames(className, styles.text)}
      style={{
        ...style,
        backgroundImage: background,
      }}
      {...props}
    >
      {children}
    </Text>
  )
}

GradientText.propTypes = {
  children: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  colors: PropTypes.arrayOf(PropTypes.string).isRequired,
  colorStops: PropTypes.arrayOf(PropTypes.string).isRequired,
  className: PropTypes.oneOfType([PropTypes.string, PropTypes.array, PropTypes.object]),
  style: PropTypes.object,
}

export default GradientText
