export const TIMING = {
  interval: 2500,
  timeout: 15000,
}

export const MENU = [
  {
    key: 0,
    cta: 'Visit Delaware Life',
    header: 'Performance \n elevates \n possibility.',
    link: 'https://www.delawarelife.com/',
    description:
      "Delaware Life Insurance Company's flexible retirement solutions help you  save with discipline and growth with confidence.",
    offeringTitle: 'PRODUCT OFFERING',
    offering: ['Fixed Index Annuities', 'Multi-year Guaranteed Annuities', 'Variable Annuities'],
    primaryColor: '#48BFA5',
    secondaryColor: '#020541',
    heroLogo: {
      title: 'alt text',
      file: {
        url: '//images.ctfassets.net/mw8dq0fsj7wk/M6Z7mF40pKVYRqCanFfwR/745c1c0820d4830524e61ce18cb19b4d/delaware.svg',
      },
    },
    menuLogo: {
      title: 'alt text',
      file: {
        url:
          '//images.ctfassets.net/mw8dq0fsj7wk/2oTWRvbn4PyymepFCCFvuo/7a99ae336dc474524c5640f85ea2ccdc/menuDelaware.svg',
      },
    },
  },
  {
    key: 1,
    cta: 'Visit Gainbridge',
    header: 'Steady is \n smart.',
    link: 'https://gainbridge.io/',
    description:
      'Gainbridge is a digital-first annuity and life insurance agency on a mission to equip people for action. With intuitive tools and high-yield products, Gainbridge can help grow money for goals big and small.',
    offeringTitle: 'PRODUCT OFFERING',
    offering: ['Multi-Year Guaranteed Annuities', 'Single Premium Immediate Annuities'],
    primaryColor: '#1158D7',
    secondaryColor: '#FFFFFF',
    heroLogo: {
      title: 'alt text',
      file: {
        url:
          '//images.ctfassets.net/mw8dq0fsj7wk/5JmX4x9iSDyKc7mWplttcN/cc64ba4ffd90e46fc14a8438cb5c3ca6/gainbridge.svg',
      },
    },
    menuLogo: {
      title: 'alt text',
      file: {
        url:
          '//images.ctfassets.net/mw8dq0fsj7wk/6g8yhQXorjvxIXxl4oHLo5/b9d4244dfb44730896b91e65233b2a63/menuGainbridge.svg',
      },
    },
  },
  {
    key: 2,
    cta: 'Visit Clear Spring Health',
    header: 'Protecting \n your \n health.',
    link: 'https://clearspringhealthcare.com/',
    description:
      'Working with experienced field marketing organizations, Clear Spring Health serves the Medicare Advantage population, delivering high-performing products and ensuring a seamless experience with honesty and efficiency.',
    offeringTitle: 'PRODUCT OFFERING',
    offering: ['Medicare Advantage Health Insurance'],
    primaryColor: '#009FA2',
    secondaryColor: '#020541',
    heroLogo: {
      title: 'alt text',
      file: {
        url: '//images.ctfassets.net/mw8dq0fsj7wk/38r0uFTOi2KXK1VWOdWYUx/21876992e1a2e513b5015cc5cb542916/clear.svg',
      },
    },
    menuLogo: {
      title: 'alt text',
      file: {
        url:
          '//images.ctfassets.net/mw8dq0fsj7wk/56C7sPI2o5SRyh31BNvHdN/8ae4131a9135b1c14ecd7518b5497290/menuClearHealth.svg',
      },
    },
  },
  {
    key: 3,
    cta: 'Visit Clear Spring Insurance',
    header: 'Insurance \n Evolved.',
    link: 'https://clearspringinsurance.com/',
    description:
      'Clear Spring Insurance is a new, innovative property and casualty insurance company specializing in workers’ compensation.',
    offeringTitle: 'PRODUCT OFFERING',
    offering: ['Property Insurance', 'Life Insurance'],
    primaryColor: '#EEA611',
    secondaryColor: '#272929',
    heroLogo: {
      title: 'alt text',
      file: {
        url: '//images.ctfassets.net/mw8dq0fsj7wk/XtXt9JJ9Ivi72lzUfChRk/caf933dff8bed79b1f286e9675f89239/clear.svg',
      },
    },
    menuLogo: {
      title: 'alt text',
      file: {
        url:
          '//images.ctfassets.net/mw8dq0fsj7wk/5Fk7x4GLEHPzwEAIeKocrG/fc8d65099ece939b314f09b68e43f9c4/menuClearInsurance.svg',
      },
    },
  },
  {
    key: 4,
    cta: 'Visit Clear Spring Life',
    header: 'Planning \n for the \n long term.',
    link: 'http://www.clearspringlife.com/',
    description:
      "Clear Spring Life's Multi Year Guaranteed Annuity solution offers a fixed return on your investment for a healthy, happy retirement.",
    offeringTitle: 'PRODUCT OFFERING',
    offering: ['Fixed annuities'],
    primaryColor: '#60AAEF',
    secondaryColor: '#020541',
    heroLogo: {
      title: 'alt text',
      file: {
        url: '//images.ctfassets.net/mw8dq0fsj7wk/5W2kj9E4iZpGRasWbES5Gx/3ce94946c7fd8e756e59c4a7c94f2846/clear.svg',
      },
    },
    menuLogo: {
      title: 'alt text',
      file: {
        url:
          '//images.ctfassets.net/mw8dq0fsj7wk/3k5aWMVehUSYas8oeOiGX2/a28175bb6d10151224d75f3b56166bb2/menuClearLife.svg',
      },
    },
  },
]
