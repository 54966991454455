import React from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'
import PartnersAndTeam from '@components/PartnersAndTeam'

const PartnersAndTeamContainer = props => <PartnersAndTeam {...props} />

export const query = graphql`
  fragment PartnersAndTeamDataFragment on ContentfulHomePartnersAndTeam {
    partnershipsImage {
      file {
        url
      }
    }
    teamImage {
      file {
        url
      }
    }
    partnershipsDescription {
      partnershipsDescription
    }
    teamDescription {
      teamDescription
    }
    partnershipsLink {
      url
    }
    teamLink {
      url
    }
  }
`

PartnersAndTeamContainer.propTypes = {
  data: PropTypes.object.isRequired,
}

export default PartnersAndTeamContainer
