import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames/bind'
import { graphql } from 'gatsby'

import styles from './AnnouncementContainer.module.scss'
import Announcement from '../../Announcement'
const cx = classnames.bind(styles)

const AnnouncementContainer = props => (
  <Announcement
    titleColClassNames={cx('titleCol')}
    subtitleColClassNames={cx('subtitleCol')}
    circleLogoClassName={cx('circleLogo')}
    {...props}
  />
)

export const query = graphql`
  fragment AnnouncementDataFragment on ContentfulHomeAnnouncement {
    title
    description {
      internal {
        content
      }
    }
    imagevideo {
      file {
        url
      }
      description
    }
    backgroundColor
    textColor
    useOrangeGradientHeader
  }
`

AnnouncementContainer.propTypes = {
  opacityStyle: PropTypes.object,
  body: PropTypes.string.isRequired,
  heading: PropTypes.string.isRequired,
  media: PropTypes.shape({
    url: PropTypes.string,
    description: PropTypes.string,
  }),
  backgroundColor: PropTypes.string.isRequired,
  textColor: PropTypes.string.isRequired,
  useOrangeGradientHeader: PropTypes.bool.isRequired,
}

export default AnnouncementContainer
