import React from 'react'
import PropTypes from 'prop-types'

import Group1001Circle from '@components/Group1001Circle'
import Hero from '@components/Hero'

import styles from './HeroWithCircleLogo.module.scss'
import classnames from 'classnames/bind'

const cx = classnames.bind(styles)

const HeroWithCircleLogo = ({
  body,
  heading,
  className,
  heroTextClassNames,
  circleLogoClassName,
  titleColClassNames,
  subtitleColClassNames,
}) => (
  <div className={cx('hero-container', className)}>
    <Hero
      title={heading}
      subtitle={body}
      heroTextClassNames={heroTextClassNames}
      subtitleColClassNames={subtitleColClassNames}
      titleColClassNames={titleColClassNames}
    />
    <div className={cx('circleLogo', circleLogoClassName)}>
      <Group1001Circle />
    </div>
  </div>
)

HeroWithCircleLogo.propTypes = {
  opacityStyle: PropTypes.object,
  body: PropTypes.string.isRequired,
  heading: PropTypes.string.isRequired,
  className: PropTypes.string,
  heroTextClassNames: PropTypes.string,
  circleLogoClassName: PropTypes.string,
  titleColClassNames: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.string)]),
  subtitleColClassNames: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.string)]),
}

export default HeroWithCircleLogo
