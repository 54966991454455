import React from 'react'
import { graphql } from 'gatsby'
import PropTypes from 'prop-types'
import SectionHeader from '@components/SectionHeader'
import CTA from '@components/CTA'

import classnames from 'classnames/bind'
import styles from './JoinUsContainer.module.scss'

const cx = classnames.bind(styles)

const JoinUsContainer = ({ header, description, link }) => (
  <div className={cx('container', 'grid', 'tablet-grid')}>
    <SectionHeader
      className={cx('col-l', 'col-l-6', 'col-l-o-3', 'col-m', 'col-m-6', 'col-m-o-1')}
      title={header}
      text={description}
    ></SectionHeader>
    {link.url && link.label && (
      <CTA className={cx('cta')} type="primary" to={link.url}>
        {link.label}
      </CTA>
    )}
  </div>
)

export const query = graphql`
  fragment JoinUsDataFragment on ContentfulHomeJoinUs {
    header {
      header
      description {
        internal {
          content
        }
      }
    }
    link {
      url
      label
    }
  }
`

JoinUsContainer.propTypes = {
  header: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  link: PropTypes.shape({
    url: PropTypes.string,
    label: PropTypes.string,
  }),
}

export default JoinUsContainer
